<template>
  <div>
    <div class="w-content">
      <h1 class="title compressed">{{$t("ResultPayment_h1_1")}}</h1>
      <div class="mb-50">{{$t("ResultPayment_p_1")}}</div>
      <!-- <div class="mb-20">
        To Address (Recipient)
        <span class="fontBold line_left">{{transactionPackData.address}}</span>
      </div>
      <div class="mb-20">
        Transaction Amount
        <span class="fontBold line_left">{{transactionPackData.amount}}</span>
      </div>
      <div class="mb-20">
        Transaction Fee
        <span class="fontBold line_left">{{transactionPackData.fee}}</span>
      </div> -->
      <div class="mb-20">
        <a
          class="link"
          target="_blank"
          :href="`https://monitor.credits.com/CreditsNetwork/account/${publicKey}`"
        >{{$t("ResultPayment_link_1")}}</a>
      </div>
      <div class="mb-40 fontBold">
        <button @click.prevent="actionResultHandler" class="bold-link bttn margin_top_20">
          {{$t("ResultPayment_button_1")}}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import i18n from "@/i18n";
export default {
  name: "success-transaction",
  mounted() {
    console.log("success transaction");
    console.log(this.transactionPackData);
  },
  computed: {
    ...mapState({
      transactionResponse: state => state.transactionCompletedResponse,
      transactionPackData: state => state.transactionPackData,
      publicKey: state => state.publicKey
    })
  },
  create(){
    this.$loading(false);
  },
  mounted(){
     this.$loading(false);
  },
  destroyed() {},
  methods: {
    actionResultHandler: function() {
      this.$router.push(`/${i18n.locale}/wallet/perform-transaction`);
    }
  }
};
</script>